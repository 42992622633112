import * as t from 'io-ts';
import {IntegerSchema} from 'favorlogic-utils';

import {LaborerRoleSchema} from 'types/model/enumeration/Role';

const optionalPart = t.partial({});
const requiredPart = t.interface({
    id: IntegerSchema,
    firstName: t.string,
    lastName: t.string,
    username: t.string,
    enabled: t.boolean,
    role: LaborerRoleSchema,
    tag: t.literal('UserDetailsForLaborer'),
});

export const UserDetailsForLaborerSchema = t.exact(t.intersection([optionalPart, requiredPart]));

export interface UserDetailsForLaborer extends t.TypeOf<typeof UserDetailsForLaborerSchema> {}
